import React from 'react'
import { PrettyDateTime, PrettyCurrency } from '@mainstem/mainstem-react-app'
import { Link } from 'react-router-dom'
import { Button, Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBuilding } from '@fortawesome/pro-duotone-svg-icons'

/* =============================================================================
                            invoiceTrackerColumns
============================================================================= */

export const invoiceTrackerColumns = () => {
  return [
    {
      dataField: 'orderId',
      text: 'Invoice ID',
      sort: true,
      formatter: (id, data, index) => {
        return (
          <React.Fragment key={index}>
            <Link to={'/requisitions/details/' + data.orderUUID}>
              <Button color='primary'>
                <h5>
                  <FontAwesomeIcon icon={faSearch} />
                  &nbsp;{data.invoiceID}
                </h5>
              </Button>
            </Link>

            <br />
            <br />

            <div>
              <small>Order Customer:</small>
              <br />
              <small>
                <Link to={'/locations/details/' + data.customerID}>
                  <Button color='primary' size='sm'>
                    <FontAwesomeIcon icon={faSearch} />
                    &nbsp;{data.customerID}-{data.customerName}
                  </Button>
                </Link>
              </small>
            </div>

            <br />

            <div>
              <small>Organization:</small>
              <br />
              <small>
                <Link to={'/organization/details/' + data.organizationId}>
                  <Button color='primary' size='sm'>
                    <FontAwesomeIcon icon={faBuilding} />
                    &nbsp;{data.organizationName}
                  </Button>
                </Link>
              </small>
              <br />
              <br />
              <Badge color='soft-primary'>Program Type: {data.programType}</Badge>
            </div>

            {data.owner_contact_phone || data.owner_contact_email ? <hr /> : <React.Fragment />}

            {data.owner_contact_phone ? (
              <div>
                <small>
                  Owner Phone:&nbsp;
                  {data.owner_contact_phone}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
            {data.owner_contact_email ? (
              <div>
                <br />
                <small>
                  Owner Email:&nbsp;
                  {data.owner_contact_email}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        )
      }
    },

    /* ======================

    ====================== */

    {
      dataField: 'shipmentType',
      text: 'Shipment Type',
      sort: true,
      formatter: (id, data, index) => {
        const invoiceBadgeColors = {
          Open: 'soft-warning',
          Approved: 'soft-info',
          Cancelled: 'soft-danger',
          Closed: 'soft-danger',
          Complete: 'soft-success',
          Shipped: 'soft-success'
        }
        return (
          <React.Fragment key={index}>
            <Badge color={invoiceBadgeColors[data.fulfillmentStatus]} style={{ width: '100%' }}>
              {data.fulfillmentStatus}
            </Badge>

            <br />

            <h6>{data.isPrivateSupplier ? '*Private Supplier*' : ''}</h6>

            <br />

            <div>Fulfillment Status:&nbsp;{data.fulfillmentStatus}</div>

            <br />

            <div>
              <small>Supplier:&nbsp;</small>
              <small>
                <Link to={'/suppliers/details/' + data.supplierID}>
                  <Button color='primary' size='sm'>
                    <FontAwesomeIcon icon={faBuilding} />
                    &nbsp;{data.supplierID}-{data.supplierName}
                  </Button>
                </Link>
              </small>
            </div>

            <br />

            <div>
              <small>Invoice Total:&nbsp;</small>
              <PrettyCurrency value={data.invoiceTotal} />
            </div>

            <br />

            <div>
              <small>Shipment Types:&nbsp;</small>
              {data.shipmentType} - {data.subType}
            </div>

            <br />

            <div>
              <small>Supplier SO#:&nbsp;</small>
              {data.fulfillmentOrderNumber ? <small>{data.fulfillmentOrderNumber}</small> : <small>Not Set</small>}
            </div>

            {data.paymentStatusComplete && (
              <>
                <br />
                <div>
                  <small>Payment Methods:&nbsp;</small>
                  {data.paymentMethods}
                </div>
              </>
            )}

            {data.supplier_contact_phone || data.supplier_contact_email ? <hr /> : <React.Fragment />}

            {data.supplier_contact_phone ? (
              <div>
                <small>
                  Supplier Phone:&nbsp;
                  {data.supplier_contact_phone}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}

            {data.supplier_contact_email ? (
              <div>
                <small>
                  Supplier Email:&nbsp;
                  {data.supplier_contact_email}
                </small>
              </div>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        )
      }
    },

    /* ======================

    ====================== */

    {
      dataField: 'lastInvoiceNote',
      text: 'Note',
      formatter: (id, data, index) => {
        return (
          <React.Fragment key={index}>
            <small>
              {data.lastInvoiceNoteCreatedBy}
              <hr />
              <PrettyDateTime datetime={data.lastInvoiceNoteDate} /> {data.lastInvoiceNoteDate ? ':' : ''}
            </small>
            <div style={{ maxWidth: '200px' }}>{data.lastInvoiceNote}</div>
          </React.Fragment>
        )
      }
    },

    /* ======================

    ====================== */

    {
      dataField: 'hasProblem',
      text: 'Quick View',
      formatter: (id, data, index) => {
        const paymentStatusColors = {
          Open: 'danger',
          Complete: 'soft-success'
        }
        return (
          <React.Fragment key={index}>
            <Badge color={data.hasProblem ? 'danger' : 'soft-success'} style={{ width: '100%' }}>
              <small>Has Problems:&nbsp;{data.hasProblem ? 'Yes' : 'No'}</small>
            </Badge>
            <br />
            <Badge color={paymentStatusColors[data.paymentStatus]} style={{ width: '100%' }}>
              <small>Payment Status:&nbsp;{data.paymentStatus}</small>
            </Badge>
            <br />
            <Badge color={data.dateApproved ? 'soft-success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Order Approved:&nbsp;
                {data.dateApproved ? (
                  <PrettyDateTime datetime={new Date(data.dateApproved)} />
                ) : (
                  <span>Not Approved</span>
                )}
              </small>
            </Badge>

            <br />

            <Badge color={data.dateAcknowledged ? 'soft-success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Order Acknowledged:&nbsp;
                {data.dateAcknowledged ? (
                  <PrettyDateTime datetime={new Date(data.dateAcknowledged)} />
                ) : (
                  <span>Not Acknowledged</span>
                )}
              </small>
            </Badge>

            <br />
            <Badge color={data.notified ? 'soft-success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Supplier Notified:&nbsp;{' '}
                {data.notified ? <PrettyDateTime datetime={new Date(data.dateNotified)} /> : <span>Not Notified</span>}
              </small>
            </Badge>
            <br />
            <Badge
              color={data.dateRejected ? 'danger' : data.dateAccepted ? 'soft-success' : 'soft-warning'}
              style={{ width: '100%' }}
            >
              <small>
                {data.dateRejected ? (
                  <>
                    Invoice Rejected:&nbsp; <PrettyDateTime datetime={new Date(data.dateRejected)} />
                  </>
                ) : data.dateAccepted ? (
                  <>
                    Invoice Accepted:&nbsp; <PrettyDateTime datetime={new Date(data.dateAccepted)} />
                  </>
                ) : (
                  <>
                    Invoice Accepted/Rejected:&nbsp; <span>Not Set</span>
                  </>
                )}
              </small>
            </Badge>
            <Badge color={data.shipmentDate ? 'soft-success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Ship Date:&nbsp;
                {data.shipmentDate ? <PrettyDateTime datetime={new Date(data.shipmentDate)} /> : <span>Not Set</span>}
              </small>
            </Badge>

            <br />

            <Badge color={data.hasTrackingDetails === false ? 'danger' : 'soft-success'} style={{ width: '100%' }}>
              <small>Has Tracking: {data.hasTrackingDetails ? 'true' : 'false'}</small>
            </Badge>

            <br />

            <Badge color={data.dateAccepted ? 'soft-success' : 'danger'} style={{ width: '100%' }}>
              <small>
                Acceptance Status:&nbsp;
                {data.dateAccepted && (
                  <React.Fragment>
                    Accepted On <PrettyDateTime datetime={new Date(data.dateAccepted)} />
                  </React.Fragment>
                )}
                {data.dateRejected && (
                  <React.Fragment>
                    Rejected On <PrettyDateTime datetime={new Date(data.dateRejected)} />
                  </React.Fragment>
                )}
                {!data.isAccepted && !data.isRejected && data.isPending && 'Pending'}
              </small>
            </Badge>
          </React.Fragment>
        )
      }
    }
  ]
}

/* =============================================================================
                            invoiceTrackerPageOptions
============================================================================= */

export const invoiceTrackerPageOptions = (page, pageSize, totalSize) => {
  return {
    page: page,
    sizePerPage: pageSize,
    totalSize: totalSize,
    onPageChange: () => {
      // console.log(page, sizePerPage)
    },
    sizePerPageList: [
      {
        text: '5',
        value: 5
      },
      {
        text: '10',
        value: 10
      },
      {
        text: '50',
        value: 50
      }
    ]
  }
}

/* =============================================================================
                            invoiceTrackerRemoteOptions
============================================================================= */

// If we want to override table filtering, sorting, pagination pass in Remote Options.
export const invoiceTrackerRemoteOptions = (onTableChangeFn) => {
  return {
    remote: {
      pagination: true,
      filter: true,
      sort: true
    },
    onTableChange: (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
      if (onTableChangeFn) onTableChangeFn(type, page, sizePerPage, filters, sortField, sortOrder, cellEdit)
    }
  }
}