import { Alert, Card, CardBody, CardHeader, CardHeaderTitle, Tab, Tabs } from '@mainstem/react-mainstem'
import { NotDone } from 'components'
import {
  SupplierIntegrationGlobalIndustrialShipmentEstimator,
  SupplierIntegrationGlobalIndustrialTableOrder
} from './components'

const SupplierIntegrationGlobalIndustrial: React.FC = () => {
  return (
    <>
      <Card>
        <CardHeader
          title={
            <>
              <img
                alt='Global Industrial Logo'
                src={'https://mainstem.blob.core.windows.net/files/7e5342cf-b6b9-41e5-97e1-6906d1587be3.png'}
                style={{ width: 100 }}
              />
              &nbsp;<CardHeaderTitle>Global Industrial API</CardHeaderTitle>
            </>
          }
        />
        <CardBody>
          <p>This supplier uses the Global Industrial API for integration into MainStem.</p>
          <Tabs hideCards vertical>
            <Tab title='Product Data'>
              <Alert color='info' title='Global Industrial Product Data'>
                <div>
                  <p>
                    Global Industrial writes a file nightly into their own SFTP server,"pfi_product_data_feed.txt", is a
                    full catalog of their products.
                  </p>
                  <p>
                    The Product Data file "pfi_product_data_feed.txt" is processed by MainStem via an ETL program. The
                    file is currently (as of 2024) 340MB in size. It is a "tab separated" file, but has been known to
                    have errors in its formatting in the past.
                  </p>
                </div>
              </Alert>
              <br />
              <Alert color='info' title='Global Industrial Volume Pricing Data'>
                <div>
                  <p>
                    Global Industrial writes a file nightly into their own SFTP server, "price_breaks_mainstream.txt"
                    which contains details about volume pricing.
                  </p>
                  <p>
                    The Product Data file "price_breaks_mainstream.txt" is processed by MainStem via an ETL program.
                  </p>
                </div>
              </Alert>
            </Tab>
            <Tab title='Infrastructure'>
              <NotDone />
            </Tab>
            <Tab title='Ordering'>
              <Alert color='info' title='Global Industrial Ordering'>
                <div>
                  <p>
                    When a user approves a Purchase Order on the MainStem platform for Global Industrial we process the
                    order request in real-time. We send a JSON Web API request to the Global Industrial API to place the
                    order. The only thing Global Industrial will tell us in real-time, is if they received the order
                    successfully. It does not gurantee fulfillment of the order.
                  </p>
                  <p>
                    We the periodically call the Global Industrial "Order Status" API to check to see if the order has
                    been accepted, and if an "Order Key" has been issued for the request.
                  </p>
                </div>
              </Alert>
              <br />
              <SupplierIntegrationGlobalIndustrialTableOrder />
            </Tab>
            <Tab title='Shipments'>
              <Tabs vertical>
                <Tab title='Estimator'>
                  <SupplierIntegrationGlobalIndustrialShipmentEstimator />
                </Tab>
                <Tab title='Tracking'>
                  <NotDone />
                </Tab>
              </Tabs>
            </Tab>
            <Tab title='Payments'>
              <NotDone />
            </Tab>
            <Tab title='Logs'>
              <Tabs>
                <Tab title='Search'>
                  <NotDone />
                </Tab>
                <Tab title='Product Sync'>
                  <NotDone />
                </Tab>
                <Tab title='Orders'>
                  <SupplierIntegrationGlobalIndustrialTableOrder />
                </Tab>
                <Tab title='Shipments'>
                  <NotDone />
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  )
}

export { SupplierIntegrationGlobalIndustrial }
