// React
import { useState } from 'react'
// MainStem - UI
import { Button, Loader, Table, theme, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminIntegrationsGlobalIndustrialOrderLogAPIResponseAmazonOrderLogDetails
} from 'api-new'
// Local - Table Column Definitions
import { columns } from './columns'

const SupplierIntegrationGlobalIndustrialTableOrder: React.FC = () => {
  // View State - Data
  const [logs, setLogs] = useState<
    MainStemAPIControllersAdminIntegrationsGlobalIndustrialOrderLogAPIResponseAmazonOrderLogDetails[]
  >([])
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)

  const loadData = () => {
    setLoading(true)
    MainStemApi.api
      .integrationsGlobalIndustrialOrderLog()
      .then((apiResponse) => {
        setLogs(apiResponse.data.logs)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Table
            actions={
              <>
                <Button
                  icon={theme.icons.refresh}
                  loading={loading}
                  onClick={() => {
                    loadData()
                  }}
                >
                  Refresh
                </Button>
              </>
            }
            columns={columns}
            data={logs}
            keyField='partitionKey'
            loading={loading}
            title='Order Creation Logs'
          />
        </>
      )}
    </>
  )
}

export { SupplierIntegrationGlobalIndustrialTableOrder }
