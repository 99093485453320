import { FormattedDateTime } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'

export const columns = [
  {
    dataField: 'timestamp',
    headerText: 'Date Created',
    formatter: (field) => {
      return <FormattedDateTime datetime={field} />
    }
  },
  {
    dataField: '',
    headerText: 'Headers',
    formatter: (_field, row) => {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              <div style={{ fontWeight: 600, flexGrow: 1 }}>X-Request-ID</div>
              <div>{row.requestId}</div>
            </div>
          </div>
        </>
      )
    }
  },
  {
    dataField: 'orderId',
    headerText: 'Requisiton'
  },
  {
    dataField: 'invoiceId',
    headerText: 'Invoice'
  },
  {
    dataField: 'requestJSON',
    headerText: 'Request JSON',
    formatter: (field) => {
      return (
        <>
          <a href={field} rel='noreferrer' target='_blank'>
            View JSON
          </a>
        </>
      )
    }
  },
  {
    dataField: 'responseJSON',
    headerText: 'Response JSON',
    formatter: (field) => {
      return (
        <>
          <a href={field} rel='noreferrer' target='_blank'>
            View JSON
          </a>
        </>
      )
    }
  }
] as ITableColumn[]
