import { Fragment, useState } from 'react'
import { ISupplierIntegrationGlobalIndustrialShipmentEstimatorLineItem } from './types'
import {
  MainStemApi,
  MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIResponseLineItemDetails
} from 'api-new'
import {
  BasicTable,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  FormattedCurrency,
  FormattedDateTime,
  Grid,
  GridItem,
  InputNumber,
  InputText,
  MainStemSelectCountry,
  PageTitle,
  theme
} from '@mainstem/react-mainstem'
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'

const SupplierIntegrationGlobalIndustrialShipmentEstimator: React.FC = () => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const [shipmentEstimate, setShipmentEstimate] = useState<
    MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIResponseLineItemDetails[]
  >([])
 
  const [lineItems, setLineItems] = useState<ISupplierIntegrationGlobalIndustrialShipmentEstimatorLineItem[]>([
    {
      sku: '31580390',
      quantity: 1
    }
  ])
  const [address1, setAddress1] = useState<string>('165 E TUHAN RD')
  const [address2, setAddress2] = useState<string>('')
  const [city, setCity] = useState<string>('CYPRESS')
  const [state, setState] = useState<string>('TX')
  const [zip, setZip] = useState<string>('77429')
  const [country, setCountry] = useState<string>('US')
  const [countryOption, setCountryOption] = useState<SelectSingleValue>()
  const [sku, setSku] = useState<string>('')
  const [quantity, setQuantity] = useState<string>('1')

  const handleGetEstimate = () => {
    setLoading(true)
    setShipmentEstimate([])

    const apiRequest = {
      address: {
        address1,
        address2,
        city,
        state,
        zip,
        country
      },
      lineItems
    }

    MainStemApi.api
      .integrationsGlobalIndustrialShipmentEstimate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setShipmentEstimate(apiResponse.data.lineItems || [])
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const LineItemRow = (lineItem: ISupplierIntegrationGlobalIndustrialShipmentEstimatorLineItem) => {
    const matchingEstimate = shipmentEstimate.find((item) => item.sku === lineItem.sku)

    return (
      <Fragment key={lineItem.sku}>
        <tr>
          <td>{lineItem.sku}</td>
          <td>{lineItem.quantity}</td>
          <td>
            <Button
              color='danger'
              icon={theme.icons.delete}
              onClick={() => {
                setLineItems(lineItems.filter((item) => item.sku !== lineItem.sku))
              }}
            />
          </td>
        </tr>
        {shipmentEstimate.length > 0 && (
          <>
            <tr>
              <th>Shipment</th>
              <th>Delivery Date</th>
            </tr>
            <tr>
              <td>
                {matchingEstimate?.cost ? <FormattedCurrency value={matchingEstimate?.cost} /> : <i>N/A</i>}
                <i>via&nbsp;{matchingEstimate?.shipmentMethod || 'unknown'}</i>
              </td>
              <td>
                {matchingEstimate?.dateEstimatedDelivery ? (
                  <span>
                    <FormattedDateTime datetime={matchingEstimate.dateEstimatedDelivery} />
                  </span>
                ) : (
                  <i>N/A</i>
                )}
              </td>
            </tr>
          </>
        )}
      </Fragment>
    )
  }

  return (
    <>
      <PageTitle
        actions={
          <>          
            <Button
              color='fancy'
              icon={theme.icons.mainstem.shipments}
              loading={loading}
              onClick={() => {
                handleGetEstimate()
              }}
            >
              Estimate Shipment Costs
            </Button>
          </>
        }
        subtitle='Use this tool to estimate the cost of shipping items from Global Industrial any address.'
        title='Global Industrial - Shipment Estimator'
      />
      <br />
      <Grid cols={2}>
        <GridItem colSpan={1}>
          <Card>
            <CardHeader title={<CardHeaderTitle>Ship To Address</CardHeaderTitle>} />
            <CardBody>
              <Grid cols={1}>
                <GridItem>
                  <InputText
                    label='Address 1'
                    onChange={(newValue) => {
                      setAddress1(newValue)
                    }}
                    value={address1}
                  />
                </GridItem>
              </Grid>
              <Grid cols={1}>
                <GridItem>
                  <InputText
                    label='Address 2'
                    onChange={(newValue) => {
                      setAddress2(newValue)
                    }}
                    value={address2}
                  />
                </GridItem>
              </Grid>
              <Grid cols={3}>
                <GridItem colSpan={1}>
                  <InputText
                    label='City'
                    onChange={(newValue) => {
                      setCity(newValue)
                    }}
                    value={city}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <InputText
                    label='State'
                    onChange={(newValue) => {
                      setState(newValue)
                    }}
                    value={state}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <InputText
                    label='Zip'
                    onChange={(newValue) => {
                      setZip(newValue)
                    }}
                    value={zip}
                  />
                </GridItem>
              </Grid>
              <Grid cols={1}>
                <GridItem>
                  <MainStemSelectCountry
                    defaultValue='US'
                    label='Country'
                    onChange={(newValue) => {
                      setCountryOption(newValue)
                      setCountry(newValue?.value)
                    }}
                    value={countryOption}
                  />
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={1}>
          <Card>
            <CardHeader title={<CardHeaderTitle>Line Items</CardHeaderTitle>} />
            <CardBody>
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <InputText
                    label='SKU'
                    onChange={(newValue) => {
                      setSku(newValue)
                    }}
                    value={sku}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <InputNumber
                    label='Quantity'
                    onChange={(newValue) => {
                      setQuantity(newValue)
                    }}
                    value={quantity}
                  />
                </GridItem>
              </Grid>
              <Button
                block
                icon={theme.icons.new}
                onClick={() => {
                  setLineItems([...lineItems, { sku, quantity: parseInt(quantity) }])
                  setSku('')
                  setQuantity('1')
                }}
              >
                Add New Line Item
              </Button>
              <br />
              <br />
              <BasicTable>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th style={{ width: 30 }} />
                  </tr>
                </thead>
                {lineItems.map((lineItem) => (
                  <Fragment key={lineItem.sku}>{LineItemRow(lineItem)}</Fragment>
                ))}
              </BasicTable>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </>
  )
}

export { SupplierIntegrationGlobalIndustrialShipmentEstimator }
